
import React, { Component } from "react";
import "./App.css";

class BookTime extends React.Component {
//<meta name="viewport" content="width=device-width, initial-scale=1.0">


btnBook(){
  window.open("https://patient.nu/portal/calendar/71f38df2-ba62-11ec-99ba-fa163e329242");
}



  render() {
    return (
      <div> 

        <div class="container">
          <div class="row">
            <div class="col-4">
              <br></br>
              <img className="coloList" src="a3.jpg" alt="c2"></img>
              <br></br>
              <br></br>
            </div>
            <div class="col-7">
              <br></br>
              <div className="titleText">
                Boka tid
              </div>
 
              <br></br>

              <div className="bodyText">
                  Tid för nybesök eller återbesök bokas via nedanstående länk.  
                </div>


              <br></br>

              <div className="bodyText">
                <button type="button" className="bookButton" onClick={this.btnBook.bind(this)}>
                  TRYCK HÄR FÖR ATT BOKA TID
                </button>
              </div>

              <br></br>

              <br></br>

            

              <div className="bodyTextBold">Telefon</div>

              <div className="bodyText">
                  Det går även bra att ringa på 0735 - 09 49 37. 
                </div>

              <br></br>

              <div className="bodyTextBold">Pris</div>

              <div className="bodyText">
                Ett besök kostar 200:- och ingår i högkostnadsskyddet. Frikort gäller.
              </div>

              <br></br>

              



            </div>

          </div>
        </div>

        <hr class="bo"></hr>

        <div className="infoTextAll">
        <div class="infoTextBold">Nordfysioterapi AB </div>
        <div>Åse Nordström, leg. fysioterapeut</div>
          Gullbergs Strandgata 6, 411 04 Göteborg

          <br></br>
          <br></br>

          <div class="info-group">
            <div class="infoTextBold">Telefon </div>
            <div class="infoText">: 0735 - 09 49 37</div>
          </div>

          <div class="info-group">
            <div class="infoTextBold">E-post </div>
            <div class="infoText">: nordfysioterapi@gmail.com</div>
          </div>

          <br></br>
          <div>Ett besök kostar 200:- och ingår i högkostnadsskyddet. Barn och ungdomar till och med 19 år, personer som fyllt 85 år samt patienter med frikort betalar ingen patientavgift.</div>

          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
        </div>

      </div>
    );
  }
}

export default BookTime; 