
import React from "react";
import "./App.css";



class Home extends React.Component {
  render() {
    return (
      <div>
        <div class="container">
          <div class="row">
            <div class="col-4">
              <br></br>
              <img className="tempHome" src="a4.jpg" alt="c3"></img>
              <img className="tempHome" src="b1.jpg" alt="c3"></img>
              <br></br>
              <br></br>
            </div>
            <div class="col-7">
              <div className="bodyText">
                <br></br>
                <div className="titleText">
                  Välkommen till Nordfysioterapi!
                </div>

                <br></br>

                <div className="bodyText">
                Välkommen till mig, Åse Nordström, leg. fysioterapeut, för bedömning och behandling samt förebyggande 
                åtgärder om du har led-, muskel eller nervrelaterad problematik. 
                </div>

                <br></br>

                <div className="bodyText">
                2016 tog jag examen i OMT steg 3 och jag jobbar i huvudsak enligt denna inriktning. 
                Jag behandlar även med akupunktur, träningsterapi (MTT), McKenzie (MDT) och dry needling. 
                Jag har även utbildning i bedömning av barns motoriska utveckling. 
                </div>

                <br></br>

                <div className="bodyText">
                Min klinik finns på Gullbergs Strandgata 6 precis vid nya Hisingsbron. 
                Du kan boka tid via nätet eller via telefon (se kontaktuppgifter). 
                </div>

                <br></br>
                <br></br>




                <div class="aktuellt"> 
                  <div className="titleTextInfo">
                    AKTUELLT
                  </div>
                  
                </div>


                <br></br>












                <br></br>
               



                




















              </div>
            </div>
          </div>
        </div>

        <hr class="bo"></hr>
        
        <div className="infoTextAll">
        <div class="infoTextBold">Nordfysioterapi AB </div>
          <div>Åse Nordström, leg. fysioterapeut</div>
          Gullbergs Strandgata 6, 411 04 Göteborg

          <br></br>
          <br></br>

          <div class="info-group">
            <div class="infoTextBold">Telefon </div>
            <div class="infoText">: 0735 - 09 49 37</div>
          </div>

          <div class="info-group">
            <div class="infoTextBold">E-post </div>
            <div class="infoText">: nordfysioterapi@gmail.com</div>
          </div>

          <br></br>
          <div>Ett besök kostar 200:- och ingår i högkostnadsskyddet. Barn och ungdomar till och med 19 år, personer som fyllt 85 år samt patienter med frikort betalar ingen patientavgift. </div>

          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
        </div>

      </div>
    );
  }
}

export default Home; 