import React from "react";
import "./App.css";


class Competence extends React.Component {
  render() {
    return (
      <div>
        <div class="container">
          <div class="row">
            <div class="col-4">
              <br></br>
              <img className="coloList" src="a2.jpg" alt="c1"></img>
              <img className="coloList" src="a7.jpg" alt="c3"></img>
              <br></br>
              <br></br>
            </div>
            <div class="col-7">
              <br></br>
              <div className="titleText">
                Kompetens och behandlingar
              </div>


              <br></br>

              <div className="bodyTextBold">Ortopedisk manuell terapi (OMT)</div>

              <div className="bodyText">
              Jag är utbildad inom ortopedisk manuell terapi (OMT). 
              OMT är ett internationellt koncept som innefattar en grundlig anammnes, 
              undersökning och bedömning/åtgärdsplan i samråd med patienten. 
              För mer information om OMT, <a class="fungera" href="https://www.fysioterapeuterna.se/Om-forbundet/Sektioner/OMT/Vad-ar-OMT/">klicka här</a>. 
              </div>

              <br></br>

              <div className="bodyTextBold">Övrig kompetens</div>

              <div className="bodyText">
              Utöver OMT är jag utbildad inom akupunktur, dry needling (triggerpunktsbehandling), McKenzie (MDT) och träningsterapi (MTT). Jag jobbar också med att bedöma motorisk utveckling hos allt från bebisar till lite större barn. 
              </div>

              <br></br>

              <div className="bodyText">
                Jag har jobbat med företagshälsovård under 10 års tid. Jag erbjuder ergonomisk genomgång av arbetsplatser, både på kontor och 
                inom produktion samt föreläsningar inom belastningsergonomi. 
              </div>

              <br></br>

                <div className="bodyTextBold">Fungera</div>

              <div className="bodyText">
              Ett gott samarbete med Fungera Rehabklinik gör att vi har bred kompetens i lokalen. 
              Vi har även tillgång till stora utrymmen med gruppverksamhets- och träningssalar. 
              För mer information om Fungera Rehabklinik, <a class="fungera" href="https://www.fungera.info">klicka här</a>. 
              </div>

              <br></br>
              
            </div>
          </div>
        </div>

        <hr class="bo"></hr>

        

        <div className="infoTextAll">
        <div class="infoTextBold">Nordfysioterapi AB </div>
        <div>Åse Nordström, leg. fysioterapeut</div>
          Gullbergs Strandgata 6, 411 04 Göteborg

          <br></br>
          <br></br>

          <div class="info-group">
            <div class="infoTextBold">Telefon </div>
            <div class="infoText">: 0735 - 09 49 37</div>
          </div>

          <div class="info-group">
            <div class="infoTextBold">E-post </div>
            <div class="infoText">: nordfysioterapi@gmail.com</div>
          </div>

          <br></br>
          <div>Ett besök kosta 200:- och ingår i högkostnadsskyddet. Barn och ungdomar till och med 19 år, personer som fyllt 85 år samt patienter med frikort betalar ingen patientavgift.</div>

          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
        </div>

      </div>
    );
  }
}

export default Competence; 