import React, { Component } from "react";
import ReactDOM from "react-dom";


import "./App.css";
import Home from "./Home";
import BookTime from "./BookTime";
import Competence from "./Competence";
import Contact from "./Contact";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";

import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";


class App extends Component { 
  constructor() {
    super();
  }
 
  render() {
     
    return ( 
      <Router>
        
        <div className="main">
          <div className="header">
             
          <img className="topLogo" src="logtemp.jpg" alt="ab"></img>

          

              <ul>
                <nav className="nav" class="navbar navbar-inverse">

                <a></a>

                  <a className="link">
                    <Link className="to-link" to="/">
                      HEM
                    </Link>
                  </a>

                  <a className="link">
                    <Link className="to-link" to="/Competence">
                      KOMPETENS OCH BEHANDLINGAR
                    </Link>
                  </a>

                  <a className="link">
                    <Link className="to-link" to="/BookTime">
                      BOKA TID
                    </Link>
                  </a>

                  <a className="link">
                    <Link className="to-link" to="/Contact">
                      KONTAKT
                    </Link>
                  </a>

                  <a> </a>


                </nav> 
                <Routes>
              <Route exact path="/" element={<Home/>} />
              <Route path="/BookTime" element={<BookTime/>} />
              <Route path="/Competence" element={<Competence/>} />
              <Route path="/Contact" element={<Contact/>} />
              
            </Routes>
              </ul> 
            
            
          </div>


          
        </div>
        
        
      </Router>
      
      
    );
    
  }
  
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);

export default App;


